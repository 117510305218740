import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import { Search as SearchIcon } from "@mui/icons-material";
import Logo from "../../assets/images/updatedLogo.png";
import { ReactComponent as SmallLogo } from "../../assets/images/updateSmallLogo.svg";
import { ReactComponent as SearchIcon1 } from "../../assets/images/SearchIcon1.svg";
import { ReactComponent as DropdownArrow } from "../../assets/images/down.svg";
import { ReactComponent as UpArrow } from "../../assets/images/up.svg";

import { ReactComponent as MenuIcon } from "../../assets/images/Icon.svg";
import Arrow1 from "../../assets/images/Vector.svg";
import Up from "../../assets/images/up.svg";
import Down from "../../assets/images/down-arrow.svg";

import SearchBar from "material-ui-search-bar";
import "./header-desktop.css";
import { useLocation, useNavigate } from "react-router-dom";
import { event } from "jquery";
const drawerWidth = 300;
const navItems = [
  { title: "ABOUT US", url: "/about-us" },
  { title: "PRODUCTS", url: "/products" },
  { title: "SOLUTIONS", url: "/industry-solutions" },
  { title: "INSIGHTS", url: "/insights" },
  { title: "CAREERS", url: "/careers" },

  // { title: "PARTNER WITH US", url: "/" },
];
let mouseEnterTimeout;
function DrawerAppBar(props) {
  const navigate = useNavigate();
  const routes = [
    "/",
    "/about-us",
    "/partner-with-us",
    "/careers",
    "/products",
    "/industry-solutions",
    "/blog",
    "/about-us",
    "/product-detail",
    "/products-listing",
    "/privacy-policy",
    "/industry-solution-details",
    "/insights",
    "/insights-details",
    "/about-us/company-updates",
    '/terms-conditions'

    // Add more routes as needed
  ];
  const location = useLocation();

  // Check if the current path matches any of the routes
  const currentPath = location.pathname;
  const isKnownRoute = routes.includes(currentPath);
  const { window } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [companyUpdatesOpen, setCompanyUpdatesOpen] = useState(false); // Track the dropdown open state

  const [mobileOpen, setMobileOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [showAboutMobile, setShowAboutMobile] = useState(false);

  const theme = useTheme();
  const [selectedSubroute, setSelectedSubroute] = useState("");
  console.log(location.pathname, "ii");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleSearchClick = () => {
    setSearchOpen(!searchOpen);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverItemClick = (subroute) => {
    setSelectedSubroute(subroute);
    setCompanyUpdatesOpen(false)

    handleClose(); // Close the popover
    navigate(subroute); // Navigate to the selected subroute
  };
  const handleRedirection = (url) => {
    if (typeof window !== 'undefined') {
      console.log(url, "nn");
      window.open(url, '_blank');
      // setCompanyUpdatesOpen(false); // Uncomment if needed
      handleClose(); // Close the popover
    } else {
      console.log("window object is not available");
    }
  };
  const handleClick = (event) => {
    console.log(event, "event");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MENU
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <React.Fragment key={item.title}>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                 
                    navigate(item.url); // Navigate directly for other items
                  
                }}
                sx={{ textAlign: "center" }}
              >
                <ListItemText primary={item.title} />
                {item.title === "ABOUT US" && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent parent onClick from firing
                      setShowAboutMobile(!showAboutMobile);
                    }}
                  >
                    {showAboutMobile ? <UpArrow/> : <DropdownArrow/>}
                  </button>
                )}
              </ListItemButton>
           
            </ListItem>
  
            {/* Conditionally render the sub-menu for "About Us" */}
            {showAboutMobile && item.title === "ABOUT US" && (
              <Box >
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => navigate("/about-us#company")}
                    sx={{ textAlign: "center" }}
                  >
                    <ListItemText primary="OUR COMPANY" sx={{
                        color:
                        location.pathname == "/about-us#company" ? "#DB1F51" : "#000",
                     
                    }} />
                  </ListItemButton>
                </ListItem>
  
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => navigate("/about-us#csr")}
                    sx={{ textAlign: "center" }}
                  >
                    <ListItemText primary="CSR" sx={{
                        color:
                        location.pathname == "/about-us#csr" ? "#DB1F51" : "#000",
                     
                    }} />
                  </ListItemButton>
                </ListItem>
  
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => navigate("/about-us#services")}
                    sx={{ textAlign: "center" }}
                  >
                    <ListItemText sx={{
                        color:
                        location.pathname === "/about-us#services" ? "#DB1F51" : "#000",
                     
                    }} primary="OUR SERVICES" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => navigate("/about-us/company-updates")}
                    sx={{ textAlign: "center", }}
                  >
                    <ListItemText primary="COMPANY UPDATES" 
                    sx={{
                        color:
                        location.pathname == "/about-us/company-updates" ? "#DB1F51" : "#000",
                     
                    }} />
                  </ListItemButton>
                </ListItem>
              </Box>
            )}
          </React.Fragment>
        ))}
  
        {isSmallScreen && (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate("/partner-with-us");
              }}
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary="PARTNER WITH US" />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );
  

  const container =
    window !== undefined ? () => window().document.body : undefined;




    const handleCompanyUpdatesClick = () => {
      setCompanyUpdatesOpen((prev) => !prev); // Toggle the dropdown
    };
  
  return isKnownRoute ? (
    <Box className="container" sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar  component="nav">
        <Toolbar
          sx={{
            background: "#FFF",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: { xs: "none", sm: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <List sx={{ display: { xs: "none", sm: "flex" }, ml: 2 }}>
              {navItems.map((item) => (
                <ListItem key={item} disablePadding>
                  <ListItemButton
                    aria-describedby={id}
                    onMouseEnter={(event) => {
                      if (item.title === "ABOUT US") {
                        
                        handleClick(event);
                      }
                    }}
                   
                    onClick={() => {
                      console.log("clicked");
                      
                      if (item.title === "ABOUT US" ) {
                        console.log("Clicked on ABOUT US");
                        navigate(item.url);
                      }else{
                        navigate(item.url);
                      }

                    }}
                    sx={{ textAlign: "center",backgroundColor:isHovered ? '#f0f0f0' : 'transparent',}}
                  >
                    <ListItemText
                      primary={item.title}
                      sx={{ fontWeight: 500, fontSize: 13 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <IconButton
              color="inherit"
              edge="center"
              onClick={() => {
                navigate("/");
              }}
              sx={{
                width: 100,
                height: 39,
                ":hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <img
                src={Logo}
                alt="image160I2026"
                style={{ height: 43, width: "100%" }}
              />
            </IconButton>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <SearchBar
                searchIcon={<SearchIcon1 />}
                style={{
                  width: 223,
                  height: 40,
                  fontSize: 12,
                  marginRight: "46px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                {/* <Select
                labelId="language-select-label"
                id="language-select"
                value={10}
                disableUnderline
                IconComponent={DropdownArrow}
                variant="standard"
                sx={{
                  fontSize: 13,
                  textTransform: "capitalize",
                  borderRadius: 0,
                  fontWeight: 500,
                  ml: 2,
                }}
              >
                <MenuItem value="">NONE</MenuItem>
                <MenuItem value={10}>ENG</MenuItem>
              </Select> */}
                <div className="d-flex items-center">
                  <div>
                    <span className="contact-us-text  d-flex">
                      <div className="d-flex">
                        <a className=" header-menu mr-3" href="/partner-with-us">
                          PARTNER WITH US{" "}
                        </a>
                        <img className="hover:rotate-[-410deg]" src={Arrow1} />
                      </div>
                    </span>
                    <div className="contact-us"></div>
                  </div>
                </div>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton
              edge="start"
              onClick={() => {
                navigate("/");
              }}
              sx={{ width: 100, height: 50 }}
            >
              <img
                src={Logo}
                alt="Uniklinger"
                style={{ height: 35, width: "65%" }}
              />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                justifyContent: searchOpen ? "flex-end" : "space-between",
                width: searchOpen ? "80%" : "20%",
              }}
            >
              {/* <Select
                labelId="language-select-label"
                id="language-select"
                value={10}
                disableUnderline
                IconComponent={DropdownArrow}
                variant="standard"
                sx={{
                  fontSize: 13,
                  textTransform: "capitalize",
                  borderRadius: 0,
                  fontWeight: 500,
                  ml: 2,
                }}
              >
                                <MenuItem value={10}>ENG</MenuItem>
              </Select> */}
              {searchOpen ? (
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search..."
                  sx={{
                    display: { xs: "flex", sm: "none" },
                    width: searchOpen ? 150 : 86,
                    height: 27,
                    marginRight: searchOpen ? "10px" : 0,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleSearchClick}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  autoFocus
                />
              ) : (
                <IconButton
                  sx={{
                    display: { xs: "flex", sm: "none" },
                    width: 50,
                    height: 27,
                    marginLeft: searchOpen ? 0 : 0,
                  }}
                  onClick={handleSearchClick}
                >
                  <SearchIcon />
                </IconButton>
              )}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { xs: "flex", sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <nav >
        <Drawer
        className="container"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor="right"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        BackdropProps={{
          sx:{
            top:66
          }
        }}
        PaperProps={{
          sx: {
            
            backgroundColor: "white !important", // Background color
            boxShadow: 3, // Add shadow for depth
            // borderRadius: 2,
            width: "25%", // Rounded corners
            p: 2,
            marginTop: 2,
            top:'-24px !important'  // Padding
          },
          onMouseLeave: handleClose, // Close as soon as the mouse leaves the paper content

        }}
        sx={{
          '& .css-1i0e6f3-MuiModal-root-MuiPopover-root': {
            top:66

          },
        }}
        disableEnforceFocus // Disable enforcing focus to allow clicks outside
        disableAutoFocus
        disablePortal // Prevents popover from being rendered outside the DOM hierarchy
        disableScrollLock // Avoids locking the scroll behavior of the page
        disableRestoreFocus // Prevents restoring focus to the anchor element
//onPointerLeave={handleClose} // Close when mouse leaves
//onMouseOutCapture={handleClose}
      >
        <Typography
          sx={{
            p: 1,
            fontSize: 14,
            color:
              location.pathname == "/about-us#company" ? "#DB1F51" : "#000",
            ":hover": {
              color: "#DB1F51",
              cursor: "pointer",
            },
          }}
          onClick={() => handlePopoverItemClick("/about-us#company")}
        >
          Our Company
        </Typography>
        <Typography
          sx={{
            p: 1,
            fontSize: 14,
            color: location.pathname == "/about-us#csr" ? "#DB1F51" : "#000",
            ":hover": {
              color: "#DB1F51",
              cursor: "pointer",
            },
          }}
          onClick={() => handlePopoverItemClick("/about-us#csr")}
        >
          CSR
        </Typography>

        <Typography
          sx={{
            p: 1,
            fontSize: 14,
            color:
              location.pathname == "/about-us#services" ? "#DB1F51" : "#000",
            ":hover": {
              color: "#DB1F51",
              cursor: "pointer",
            },
          }}
          onClick={() => handlePopoverItemClick("/about-us#services")}
        >
          Our Manufacturing Resources
        </Typography>
        <Typography
          sx={{
            p: 1,
            fontSize: 14,
            color: location.pathname == "/about-us#rnd" ? "#DB1F51" : "#000",
            ":hover": {
              color: "#DB1F51",
              cursor: "pointer",
            },
          }}
          onClick={() => handlePopoverItemClick("/about-us#rnd")}
        >
          Research, Development & Innovation
        </Typography>
        <Typography
          sx={{
            p: 1,
            fontSize: 14,marginRight:10,
            color:
              // location.pathname == "/about-us/company-updates"
              companyUpdatesOpen
                ? "#DB1F51"
                : "#000",
            ":hover": {
              color: "#DB1F51",
              cursor: "pointer",
            },
          }}
          // onClick={() => handlePopoverItemClick("/about-us/company-updates")}
          onClick={handleCompanyUpdatesClick} // Open the dropdown on click

        >
          Company Updates  <button > {companyUpdatesOpen ? <img style={{height:12,width:12}} src={Up}/>: <img style={{height:12,width:12}} src={Down}/>}</button>
        </Typography>
          {/* Nested Dropdown */}
      {companyUpdatesOpen && (
        <Box
          sx={{
            ml: 2, // Add margin for nested items
          
          }}
        >
          <Typography
            sx={{
              p: 1,
              fontSize: 13,
              color: "#000",
              ":hover": { color: "#DB1F51", cursor: "pointer" },
              ":before": {
                content: "'• '", // Adding bullet point
             
              },
            }}
            onClick={() => handlePopoverItemClick("/about-us/company-updates")}
          >
           Corporate Announcements
          </Typography>

          <Typography
            sx={{
              p: 1,
              fontSize: 13,
              color: "#000",
              ":hover": { color: "#DB1F51", cursor: "pointer" },
              ":before": {
                content: "'• '", // Adding bullet point
            
              },
            }}
            
          
          >
            <a className="hover-link" style={{color: "#000"}} href="https://uniklinger.com/Achema#partnerWithUs">Achema Microsite</a>
          </Typography>
        </Box>
      )}
      </Popover>
    </Box>
  ) : null;
}
DrawerAppBar.propTypes = {
  window: PropTypes.func,
};
export default DrawerAppBar;
