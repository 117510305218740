import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider from "../assets/images/slider-1.png";
import product1 from "../assets/images/piston-valves.svg";
import product2 from "../assets/images/steam-traps.svg";
import product3 from "../assets/images/jointing.svg";
import product4 from "../assets/images/safety-control.svg";
import factory from "../assets/images/Mask group.png";
import engineer from "../assets/images/image 22.png";
import suman from "../assets/images/suman-majum.png";
import quote from "../assets/images/“.png";
import steel from "../assets/images/steal.png";
import blog from "../assets/images/blog-card.png";
import graphite from "../assets/images/Graphite-Sheets.png";
import ppft from "../assets/images/extended-ppft-sheets.png";
import ppft1 from "../assets/images/extended-ppft-sheets-1.png";
import arrowImage from "../assets/images/product-arrow.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, CardActions, Box } from "@mui/material";
import "./customDots.css";
import Next from "../assets/images/nextArrow.svg";
import RightSideArrow from "../assets/images/right-side-arrow.svg";
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';


import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";
import "./ProductsOffer.css";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "white",
        zIndex: 9999,
        height: 64,
        width: 64,
        borderRadius: 100,
        borderColor: " rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
        top:175,
        right:-40
     
      }}
      onClick={onClick}
    >
      <img
        style={{
          position: "absolute",
          top: 21,
          left: 27,
        }}
        src={Next}
      />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "white",
        zIndex: 9999,
        height: 64,
        width: 64,
        borderRadius: 100,
        left: -35,
        top: 175,
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
      }}
      onClick={onClick}
    >
      <img
        style={{
          position: "absolute",
          top: 21,
          left: 21,
        }}
        className="rotate-180 "
        src={Next}
      />
    </div>
  );
};

export default function ProductsOffer() {
  const [activeDot, setActiveDot] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
const [cards, setCards] = useState([]);
  const [selectedProduct,setSelectedProduct] = useState(4);
  const [isLargeDevice, setIsLargeDevice] = useState(window.innerWidth >= 1970); // Adjust width as needed

  useEffect(() => {
    const handleResize = () => {setIsMobile(window.innerWidth < 768);    setIsLargeDevice(window.innerWidth >=  1920);
   
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  var settings = {
    className: "center",
    dots: true,

    centerPadding: "0px",
    slidesToShow: isMobile ? 1 : 4,
    speed: 500,
    nextArrow: isMobile ? null : <NextArrow />,
    prevArrow: isMobile ? null : <PrevArrow />,
    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 1024, // Tablet breakpoint
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };
useEffect(() => {
    getData();
  }, [selectedProduct]);
  const BaseUrl = "https://ukladmin.3mindsdigital.com";
  const getData = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}/api/product-details?filters[product_id][$eq]=${selectedProduct}&populate=*`
      );
      setCards(response.data.data);
      console.log('cards',cards);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };


  const handleRedirection = (url) => {
    window.location.href = url;
  };
  return (
    <div className="w-full mb-[4rem] mt-[60px]  container contact-us-container">
      <h1 className="text-[20px] md:text-4xl font-bold">PRODUCTS WE OFFER</h1>

      <div className="w-full overflow-x-auto flex justify-center">
        <div className="flex pl-[400px] md:pl-0 md:w-[60%] justify-around">
          <div className="carousel-prod relative flex rounded-full flex-col items-center p-4 md:mx-2 w-[180px] h-[180px] hover:border-[#a6bcf9cc] cursor-pointer " style={selectedProduct === 1?{border:'10px solid #a6bcf9cc',width:200,height:200}:{}} onClick={()=>setSelectedProduct(1)}>
            <img
              src={product1}
              className="w-[100%] h-[80%] rounded-full "
            />
            <div className="text-white text-[14px] dmsans-bold text-center mt-2 absolute bottom-[33px]" style={{width:'90%'}}>
              Piston Valves &<div> Bellow Seal Valves</div>
            </div>
          </div>
          <div className="carousel-prod-red relative rounded-full flex flex-col items-center p-4  md:mx-2 w-[180px] h-[180px] hover:border-[#fcb8ca] cursor-pointer " style={selectedProduct === 3?{border:'10px solid #fcb8ca',width:200,height:200}:{}} onClick={()=>setSelectedProduct(3)}>
            <img
              src={product2}
              className="w-[100%] h-[80%] rounded-full "
            />
            <div className="text-white text-[14px] dmsans-bold text-center mt-2 absolute bottom-[33px]" style={{width:'90%'}}>
              Steam Traps & <div>Accesories</div>
            </div>
          </div>
          <div className="carousel-prod relative rounded-full flex flex-col items-center p-4 md:mx-2 w-[180px] h-[180px] hover:border-[#a6bcf9cc] cursor-pointer " style={selectedProduct === 4?{border:'10px solid #a6bcf9cc',width:200,height:200}:{}} onClick={()=>setSelectedProduct(4)}>
            <img
              src={product3}
              className="w-[100%] h-[80%] rounded-full"
            />
            <div className="text-white text-[14px] dmsans-bold text-center mt-2 absolute bottom-[33px]" style={{width:'90%'}}>
              Jointing Sheets & <div>Gaskets</div>
            </div>
          </div>
          <div className="carousel-prod-red relative rounded-full flex flex-col items-center p-4 md:mx-2 w-[180px] h-[180px] hover:border-[#fcb8ca] cursor-pointer  z-index-9" style={selectedProduct === 2?{border:'10px solid #fcb8ca',width:200,height:200}:{}} onClick={()=>setSelectedProduct(2)}>
            <img
              src={product4}
              className="w-[100%] h-[80%] rounded-full "
            />
            <div className="text-white text-[14px] dmsans-bold text-center mt-3 absolute bottom-[33px]" style={{width:'90%'}}>
              Safety & Control <div>Valves</div>
            </div>
          </div>

          
        
        </div>
      </div>
<div className="products-slider md:block mobileHide" style={{marginTop:'40px'}}>
<Swiper className="home"
      modules={[Navigation, Pagination]}
      spaceBetween={20}
      slidesPerView={ isLargeDevice ? 5 : 3}
      navigation={
        cards.length >= 3
          ? {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }
          : false
      }
      pagination={{ clickable: true }}
      scrollbar={{ draggable: false }}
      loop={true}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      centerInsufficientSlides
    >
      {cards.length > 0 ? (
        cards.map((card, index) => (
          <SwiperSlide key={index}>
            <Card sx={{ width: 398, height: 380, }}>
              <CardActionArea sx={{ height: '100%' }}>
                <CardMedia
                  component="img"
                  sx={{ objectFit: 'cover', height: 240 }}
                  image={BaseUrl.concat(card?.attributes?.Image?.data?.attributes?.url)}
                  alt={card.attributes.Title}
                />
                <CardContent
                  sx={{
                    height: 'calc(100% - 240px)',
                    background: '#FFFFFF',
                    border: '1px solid rgba(0, 0, 0, 0.1)',padding:3
                  }}
                >
               <div className="dmsans-bold leading-[22px] text-[24px]">
               {card?.attributes?.Title}
               </div>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontSize: '14px',
                        color: '#2A2A2A',
                        fontWeight: 400,
                      }}
                    >
                      {card.attributes.Description.slice(0, 80)}
                      <span
                        style={{
                          color: '#222222',
                          fontSize: 14,
                          fontWeight: 600,
                          paddingLeft: '5px',
                        }}
                      >
                        Read More
                      </span>
                    </Typography>
                    <button  itemProp="url" 
                  // onClick={() => {
                  //      navigate(`/products-listing?productId=${card.id}`);
                  // }}
                  onClick={() => handleRedirection(card.attributes.redirect_url)}
                  >
                    <img
                      src={arrowImage}
                      alt="arrow"
                      style={{
                        width: '48px',
                        height: '48px',
                        marginLeft: '8px',
                      }}
                    />
                    </button>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </SwiperSlide>
        ))
      ) : (
        <SwiperSlide>No data available</SwiperSlide>
      )}
  {cards.length > 2 ? 
          <>
            <NextArrow className="swiper-button-next" />
            <PrevArrow className="swiper-button-prev" />
          </>
         :null}
  
    </Swiper>
      </div>
      <div className="products-slider md:hidden">
        <Slider {...settings} className="mt-[50px]  md:h-[500px] ">
          {cards.map((card,index)=>(
            <Card key={index} sx={{ width: isMobile ? 315 : 375 ,    boxShadow: '0px 4px 26px 0px #0973D840',
          }}>
            <CardActionArea>
              <CardMedia
                component="img"
                sx={{ objectFit: "cover", height: isMobile ? "85px" : "247px" }}
                image={BaseUrl.concat(card?.attributes?.Image?.data?.attributes?.url)}
                alt="green iguana"
              />
              <CardContent>
               <div className="dmsans-bold text-[24px]">
               {card?.attributes?.Title}
               </div>
                  
              
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: "14px", color: "#2A2A2A", fontWeight: 400 }}
                  >
                    {card?.attributes?.Description.slice(0,80)}
                    <span
                      style={{
                        color: "#222222",
                        fontSize: 14,
                        fontWeight: 600,
paddingLeft:'5px'
                      }}
                    >
                      Read More
                    </span>
                  </Typography>
                  <img
                    src={arrowImage}
                    alt="arrow"
                    style={{ width: "48px", height: "48px", marginLeft: "8px" }}
                  />
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
))}
        </Slider>
      </div>
    </div>
  );
}
