import React, { useEffect, useState } from "react";
import MainBanner from "../assets/images/industry-solution-banner.png";
import MobileBanner from "../assets/images/solution-mobile-banner.png";
import ButtonArrow from "../assets/images/button-arrow.svg";
import axios from "axios";
import Form from "./Form";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import ProductsBannerListing from "../assets/images/productListingBanner.png";

const IndustrySolution = () => {
  const navigate= useNavigate()
  const [cards, setCards] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [hoverIndex, setHoverIndex] = useState(null);
  const BaseUrl = "https://ukladmin.3mindsdigital.com";

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCards();

    // Update screen width on resize
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchCards = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/api/industry-solutions?populate=*`);
      setCards(response.data.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleMouseEnter = (index) => setHoverIndex(index);
  const handleMouseLeave = () => setHoverIndex(null);

  const handleClickEnquiry = (title) => {
    const emailAddress = 'salescso@uniklinger.com';
    const subject = `${title}'s Enquiry`;
    const body = `Hey, I wanted to know more about ${title}`;
    const mailtoUrl = `mailto:${encodeURIComponent(emailAddress)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoUrl);
  };

  const isMobile = screenWidth <= 820;
  const shouldShowButtons = hoverIndex !== null || isMobile;
  function handleRoute(id){
    navigate(`/industry-solution-details?solutionId=${id}`);
  }
  return (
    <>
     <Helmet>
        <title>Industry Solutions</title>
        <link rel="canonical" href="https://uniklinger.com/industry-solutions" />

      </Helmet>
    <div className="  container" style={{paddingTop: isMobile?'100px':0}}>
    <div
          className="banner-class relative text-center"
          itemScope
          itemType="https://schema.org/Product"
        >
          <img
            src={ProductsBannerListing}
            alt="Safety Valves"
            className="banner-image"
            description="Safety Valves"
            itemProp="image"
          />
          
        </div>
      <div className="industry-card-containers">
        {cards.length === 0 ? (
          <div>No data available</div>
        ) : (
          cards.map((card, index) => (
            <div
              key={card.id}
              className="industry-card"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              itemScope
              itemType="https://schema.org/Product"
            >
              <div  style={{width:'100%'}} >
                <img
                  src={BaseUrl.concat(card.attributes.Image.data.attributes.url)}
                  alt={card.attributes.Title}
                  description={card.attributes.Description}
                  className="industry-image"
                  itemProp="image"
                  style={{width:'100%'}}
                />
              </div>
              <div className="industry-content bg-white p-20">
                <div className="industry-title pb-20" itemProp="name">
                  {card.attributes.Title}
                </div>
                {/* <div className="industry-description pb-20" itemProp="description">
                  {card.attributes.Description}
                </div> */}
                <div
               
                  className="buttons-container"
                  style={{ display: hoverIndex === index || isMobile ? "flex" : "none" }}
                  itemProp="potentialAction"
                  itemScope
                  itemType="https://schema.org/Action"
                >
                  <button
                    className="card-button-secondary-class"
                    // Uncomment and define handleRoute if needed
                    onClick={()=>{handleRoute(card.id)}}
                    itemProp="target"
                  >
                    <span>View More</span>
                  </button>
                  <button
                    className="card-button-class d-flex"
                    onClick={() => handleClickEnquiry(card.attributes.Title)}
                    itemProp="target"
                  >
                    <span className="pr-10">Enquire Now</span>
                    <span>
                      <img
                        src={ButtonArrow}
                        alt="arrow"
                        style={{ marginTop: '3px' }}
                      />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      <Form />
    </div>
    </>
   
  );
  
};

export default IndustrySolution;
